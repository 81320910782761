.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

}

.title {
    font-size: 24px;
    font-weight: bold;
    margin: 30px;
    color: #0273FD;
}

.formContainer {
    width: 80%;
    margin-bottom: 16px;
}

.formGroup {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    width: 30%;
}

.inputWrapper {
    position: relative;
}

.togglePasswordButton {
    position: absolute;
    top: 55%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

.buttonContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-bottom: 20px;
}


.textRegister {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
}

.buttonRegister {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: black;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.buttonRegister:hover {
    background-color: darkgray;
}

.textoError {
    color: #FF0000;
    margin-bottom: 15px;
    text-align: center;
}

.textoConfirm {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
}

.containerRegistro{
    margin: 10px;
}

.textoSmall {
    color: gray;
    font-size: 12px;
}

.textoIrLogin {
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;
}

.textoIrLogin:hover {
    color: #3366FF;
    text-decoration: underline;
}

@media(max-width: 1100px) {
    .formGroup {
        width: 50%;
    }
}

@media(max-width: 500px) {
    .formGroup {
        width: 80%;
    }
}