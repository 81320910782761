.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 90%;
}

.formInputTelefono{
    width: 50%;
}

.botonesModal{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.botonAceptar{
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.botonAceptar:hover{
    background-color: darkgray;
}

.botonCancelar{
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 1em;
    margin-right: 20px;
    border: 2px solid black;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.botonCancelar:hover{
    background-color: darkgray;
}

.tituloModal{
    text-align: center;
    margin-bottom: 30px;
}

.textoInfo{
    margin-bottom: 10px;
}

.icono{
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
    transition: transform 0.3s, color 0.3s; /* Añade una transición suave para el hover */
}

.icono:hover {
    transform: scale(1.2); /* Aumenta el tamaño del icono */
}

.contactoText {
    display: inline-block;
    vertical-align: middle; /* Asegura que el texto se alinee con el icono */
}


/* Estilos responsivos */
@media (max-width: 600px) {
    .modal {
        max-width: 300px;
        width: 90%;
    }
}