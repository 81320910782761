.container {
    margin-top: 50px;
    margin-bottom: 50px;
}

.formTextArea{
    min-height: 150px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}

.organizacionGroup {
    display: flex;
    flex-direction: row;
}

.iconContainer {
    display: flex;
    align-items: flex-end;
    cursor: pointer;

    margin-bottom: 30px;
    
}

.containerButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    /*    margin-top: 50px;
    margin-bottom: 50px; */
}

.volverButton {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 1em;
    margin-right: 20px;
    border: 2px solid black;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.volverButton:hover {
    background-color: darkgray;
}

.flechaVolverIcono {
    display: inline-flex;
    align-items: center;
}

.submitButton {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submitButton:hover {
    background-color: darkgray;
}

.error {
    color: #FF6F61;
}