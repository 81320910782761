.sectionForm {
    display: flex;
    margin-bottom: 50px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.eventForm {
    margin-Top: 0 auto;
    margin-bottom: 20px;
}

.formGroup {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
}


.formTextarea {
    resize: vertical;
    min-height: 150px;
}

.formArchivo {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    white-space: pre-wrap;
    font-size: 20px;
}



.selectedFile {
    max-width: 50%;
    max-height: 50%;
    margin-top: 10px;
}


.buttonSubmit {
    display: flex;
    align-items: center;
    margin: 0px auto;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.buttonSubmit:hover {
    background-color: darkgray;
}

.loading {
    display: flex;
    justify-content: center;
}

.mensaje {
    text-align: center;
}

.error {
    color: #FF4D4D;
    font-size: 15px;
    text-align: center;
}


@media (max-width: 1000px) {

    .formInput,
    .formTextarea,
    .formArchivo {
        width: calc(100% - 16px);
    }

    .formGroup {
        width: 90%;
    }
}