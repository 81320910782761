.container{
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.imageContainer {
    flex: 1;
    margin-top: 20px;
    margin-bottom: 20px;
}

.image {
    max-width: 100%;
    max-height: 700px;
}

.text{
    margin-bottom: 20px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}

.containerButton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.submitButton {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submitButton:hover {
    background-color: darkgray;
}

.error{
    color: red;
}

.errorForm{
    color: gray;
}