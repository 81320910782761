/* styles.css o index.css */
@import "leaflet/dist/leaflet.css";

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.7px;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  text-align: center;
  margin-bottom: 40px;
  font-weight: 500;
  line-height: 1.2;
  font-weight: bold;
}

h2 {
  font-weight: 500;
  line-height: 1.2;
  font-weight: bold;
}

p {
  font-weight: 500;
  line-height: 1.2
}


label {
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 10px;
  font-size: 16px;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
select,
textarea {
  display: block;
  width: 95%;
  padding: 0.375rem 0.75rem;
  font-size: 18px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #242627;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
/*   width: 90%; */
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
textarea:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

@media (max-width: 750px) {
  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="email"],
  select,
  textarea {
    width: 95%;
  }
}