.containerDetallePlan {
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  /* Crea 12 columnas iguales */
}

.column1 {
  grid-column: span 5;
  /* Ocupa 7 columnas */
}

.column2 {
  grid-column: span 7;
  /* Ocupa 5 columnas */
}

.fullWidth {
  grid-column: span 12;
  /* Ocupa 12 columnas */
}

.imageContainer {
  flex: 1;
}

.image {
  max-width: 100%;
  max-height: 700px;
}

/* .infoContainer {
  margin: 10px;
}

.infoHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
} */


.containerInfo {
  margin-top: 30px;
  margin-left: 20px;
}

.label {
  color: #8E8E93;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 10px;
}


.textoInfo {
  font-size: 16px;
  line-height: 24px;
}

.descripcion {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  
}

.containerFechaEvento {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.botonCalendario {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px 5px;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid black;
  transition: background-color 0.3s, color 0.3s;
}

.botonCalendario:hover {
  background-color: rgb(213, 207, 207);
  /* Color deseado en hover */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  /* Sombra de caja en hover */
  transform: scale(1.05);
  /* Escala ligeramente el elemento en hover */
}

.horizontalBar {
  width: 100%;
  height: 1px;

  background-color: #E0E0E0;
  margin: 30px 0;
  /* Esto combina margen superior e inferior */
}

.textoDireccion {
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
}

.containerMapa {
  margin-top: 20px;
}

.containerOrganizacion{
  margin-top: 20px;
}
.organizacion{
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.containerTelefonoContacto{
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.containerEmailContacto{
  display: flex;
  align-items: center;
  margin-top: 10px;
}



.icono{
 margin-right: 10px;
}

.contactLink {
  text-decoration: underline;
  color: black; 
}

.contactLink:hover {
  color: #4A4A4A; 
}

.reportLink {
  font-family: Arial, sans-serif;
  color: #000;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.reportLink:hover {
  color: #000;
}

.botonesContainer {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.textoBoton {
  font-size: 18px;
  color: #fff;
  text-align: center;
}

.mapContainer {
  width: 100%;
  height: 400px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.iconosDetalle {
  display: flex;
  justify-content: space-evenly;
}

.iconoDetalle {
  cursor: pointer;
  margin-left: 10px;
}


.botonesModalReporte {
  display: flex;
  justify-content: space-around;
}

.botonCancelarReporte {
  background-color: transparent;
  color: #0273FD;
  padding: 7px 15px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px;
  transition: background-color 0.3s;
}

.botonCancelarReporte:hover {
  color: #333;
  background-color: #ccc;
}

.botonConfirmarReporte {
  background-color: #0273fd;
  color: #fff;
  padding: 7px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
  font-size: 15px;
}

.botonConfirmarReporte:hover {
  background-color: #75a2e1;
  color: #ccc;
}


.textoContacto {
  color: #0273FD;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
  background-color: #fff;
  padding: 20px;
}

.modal-body {
  overflow-y: auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .containerDetallePlan {
    display: block;
    /* Elimina el grid en móviles */
  }

  .column1,
  .column2 {
    width: 100%;
    /* Cada columna ocupa todo el ancho */
    grid-column: auto;
    /* Resetea cualquier valor de grid-column */
    /* Añade espacio entre los elementos */
  }
}

@media (max-width: 600px) {
  .modal {
    max-width: 300px;
    width: 90%;
  }
  .containerInfo{
    margin-left: 0px;
  }
}

/* .map {
    width: 80%;
    height: 300px;
    justify-content: center;
    align-self: center;
}

.mapbox-improve-map {
  display: none;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl-attrib {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1000;
} */




/* .imageContainer {
  flex: 1;
  background-color: #EFEFEF;
  width: 100%;
}

.image {
  flex: 1;
}

.infoContainer {
  margin: 10px;
}

.infoHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
}



.ubicacion {
  font-size: 16px;
  color: #666;
}


.botonesContainer {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.textoBoton {
  font-size: 18px;
  color: #fff;
  text-align: center;
}

.descripcion {
  font-size: 16px;
  line-height: 24px;
}

.map {
  width: 80%;
  height: 300px;
  justify-content: center;
  align-self: center;
}
 */