.error{
    color: #FF4D4D;
    font-size: 15px;
}

.listaPlanesFavoritos{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}


@media (max-width: 950px) {
    .listaPlanesFavoritos {
        display: flex;
        flex-direction: column;
    }
}