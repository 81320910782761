.container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.textoPregunta{
    margin-bottom: 20px;
}

.buttonGroup {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.buttonDiaSemana {
    width: 40px;
    height: 40px;
    border: 2px solid black;
    border-radius: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
}

.buttonDiaSemana.selected {
    background-color: black;
    color: white;
}

.buttonDiaSemana:hover {
    background-color: rgb(213, 207, 207);
    /* Color deseado en hover */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    /* Sombra de caja en hover */
    transform: scale(1.05);
    /* Escala ligeramente el elemento en hover */
}

.schedule {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 400px;
}

.scheduleItem {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    margin-top: 20px;
}

.textoDiaSemana {
    margin-bottom: 10px;
    font-size: 14px;
}

.containerTimeInput {
    display: flex;
    align-items: center;
    gap: 10px;
}

.timeInput {
    border: 2px solid black;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
}

.timeInput span {
    margin-right: 10px;
}

.input {
    cursor: pointer;
    display: block;
  width: 100%;

  font-size: 18px;
  line-height: 1.5;
  color: black;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #242627;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

.containerSwitch {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}

.switch {
    margin-right: 10px;
    /* Ajusta el margen según sea necesario */
}