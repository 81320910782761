.locationSearchContainer {
    position: relative;
    width: 100%;
}

/* .inputButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
} */

.botonBuscar {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    font-size: 1em;
    color: white;
    background-color: black;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    margin-right: 5%;
    transition: background-color 0.3s;
}

.botonBuscar:hover {
    background-color: darkgray;
}

.iconDelete{
    position: absolute;
    top: 50%;
    right: 90px;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: 1em;
    color: white;
    background-color: rgb(193, 186, 186);
    border: none;
    border-radius: 25px;
    cursor: pointer;
    margin-right: 5%;
    transition: background-color 0.3s;
}

.iconDelete:hover {
    background-color: gray;
}


.locationSearchInput{
    border-radius: 30px;
    display: block;
  width: 95%;
  padding: 0.375rem 0.75rem;
  font-size: 18px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #242627;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.locationSearchInput:focus,
.locationSearchInput:hover {
  border-color: #66afe9;
  outline: none;
  box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
}


.locationSearchResults {
/*     width: 800px; */
    position: absolute;
    left: 0;
    max-height: 200px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin: 4px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
}

.locationSearchResults li {
    padding: 8px 12px;
    cursor: pointer;
}

.locationSearchResults li.selected {
    background-color: #f2f2f2;
}

.locationSearchResults li:hover {
    background-color: #f2f2f2;
}

.searchButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 50%;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    background-color: transparent;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    margin-left: 10px;
    transition: all 0.3s ease;
}

.searchButton:hover {
    border-color: black;
    background-color: #0273fd;
    color: white;
    transform: scale(1.1);
}

/* Archivo CSS, por ejemplo, GooglePlacesAutocomplete.module.css */

.pac-container {
    z-index: 1051 !important; /* Asegúrate de que el contenedor de sugerencias esté por encima de otros elementos */
}

.pac-container:after {
    display: none !important; /* Oculta el mensaje de "powered by Google" */
}

.pac-item {
    font-size: 14px; /* Ajusta el tamaño de fuente de las sugerencias si es necesario */
}

.pac-icon {
    display: none !important; /* Oculta los iconos junto a las sugerencias */
}



@media (max-width: 750px) {

    .locationSearchInput {
        width: 95%;
        /* Reducir el ancho en dispositivos móviles */
        /* width: calc(100% - 16px);
        margin-left: 8px;
        margin-right: 8px; */
        
    }

    .botonBuscar{
        margin-right: 5%;
    }
}