.containerCategoria {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  overflow-x: auto;
}

.categoria {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  border-radius: 25px;
  border: 2px solid black;
  transition: background-color 0.3s, color 0.3s;
}

.containerWrap {
  flex-wrap: wrap;
  justify-content: flex-start;
}

.categoriaWrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
}

.categoria:hover {
  background-color: rgb(213, 207, 207);
  /* Color deseado en hover */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  /* Sombra de caja en hover */
  transform: scale(1.05);
  /* Escala ligeramente el elemento en hover */
}

.categoriaSeleccionada {
  background-color: black;
  color: white;
}

.textoCategoria {
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.imagenCategoria {
  display: none;
}

@media (max-width: 768px) {
  .categoria {
    margin-right: 10px;
  }
}