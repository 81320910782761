.imageContainer{
    display: flex;
    justify-content: center;
}
.image {
   /*  width: 100%; */
}

.containerInfo {
    margin-top: 30px;
}

.horizontalBar {
    width: 100%;
    height: 1px;

    background-color: #E0E0E0;
    margin: 30px 0;
    /* Esto combina margen superior e inferior */
}

.icono {
    margin-right: 10px;
}

.textoDireccion {
    text-decoration: underline;
    margin-left: 5px;
    cursor: pointer;
}

.containerTelefonoContacto {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.containerEmailContacto {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.contactLink {
    text-decoration: underline;
    color: black;
}

.contactLink:hover {
    color: #4A4A4A;
}