.progressContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
}

.stepContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progressStep {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 2px solid gray;
    border-radius: 50%;
    background-color: white;
    color: gray;
    font-weight: bold;
}

.active {
    border-color: black;
    color: black;
}

.progressLine {
    flex: 1;
    height: 2px;
    background-color: gray;
    margin-bottom: 25px;
}

.completed {
    background-color: black;
}

.stepLabel {
    margin-top: 8px;
    font-size: 12px;
    color: gray;
    text-align: center;
    width: 60px; /* Ajusta esto según sea necesario */
    height: 20px; /* Fija la altura del contenedor del texto */
    line-height: 20px; /* Alinea verticalmente el texto */
}

@media (max-width: 500px) {
    .progressContainer {
        margin: 0;
    }
    .progressLine{
        margin-bottom: 25px;
    }
}