.containerFotoInicial {
  margin: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.containerFotoBestPromo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.containerVideoPublicarPlan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.descriptionSentence {
  margin: 30px
}

.descriptionSentence p {
  font-size: 20px;
  color: #555555;
  margin: 0;
  text-align: center;
}

.tituloContainer {
  text-align: center;
  margin: 50px;
}

.video{
  width: 600px;
  height: 600px;
}

.badgesStores{
  display: flex;
  justify-content: space-around;
/*   margin-top: 30px; */
  margin-bottom: 50px;
}

.googlePlayBadge {
  width: 200px;
  height: 80px;
/*   margin-top: 30px; */
}

.appleBadge {
  width: 200px;
  height: 80px;
/*   margin-top: 30px; */
}

.fotoInicial{
  margin-top: 50px;
}

.vertical {
  display: none; 
}

@media (max-width: 1100px) {
  .fotoInicial {
    width: 600px;
    height: 600px;
  }
}

@media (max-width: 600px) {
  .horizontal {
    display: none; 
  }

  .vertical {
    display: block; 
  }
  .fotoInicial {
    width: 400px;
    height: 800px;
  }
  .badgesStores {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .video, .fotoBestPromo{
    width: 100%;
    max-width: 400px; 
    height: auto;
  }
}


@media (max-width: 400px) {
  .fotoInicial {
    width: 300px;
    height: 600px;
  }
}

