/* styles.module.css */
.section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

.containerFechas {
    display: flex;
    align-items: center;
    justify-content: center;

    /* Centra el contenido horizontalmente */

}

.containerIcono {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
}

.containerIcono:hover {
    background-color: rgb(213, 207, 207);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
}

.containerTexto {
    margin-right: 8px;
    margin-left: 8px;
    text-align: center;
}


.select {
    width: 50%;
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px 40px 5px 10px;
    font-size: 16px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-left: 20px;
    appearance: none;
    background: white url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="black" d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center;
    background-size: 20px;
    box-sizing: border-box;
    /*     width: 200px; */
}

.select:hover {
    background-color: rgb(213, 207, 207);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
}

.select::-ms-expand {
    display: none;
}


@media (max-width: 500px) {
    .containerFechas {
        margin-bottom: 10px;
        flex: 1
    }

    .containerTexto {
        margin-right: 4px;
        margin-left: 4px;
    }

    .containerSelect {
        margin-left: 5px;
        display: block;
        margin: 0 auto;
    }
}