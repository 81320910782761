
.content {
    margin-left: 20%;
    margin-right: 20%;
    flex: 1;
    margin-top: 50px;
}
.contentNosotros{
    background-color: #f1d49f;
}

@media (max-width: 1200px) {
    .content {
        margin-left: 10%;
        margin-right: 10%;
    }
}

@media (max-width: 768px) {
    .content {
        margin-left: 5%;
        margin-right: 5%;
    }
}