.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%;
    max-height: 90%;
    background-color: #fff;
    padding: 20px;
}

.modal-body {
    overflow-y: auto;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.botonCerrar{
    background-color: #0273fd;
    color: #fff;
    padding: 7px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    justify-content: center;
}

.botonCerrar:hover {
    background-color: #75a2e1;
    color: #ccc;
}

.tituloModal{
    text-align: center;
    margin-bottom: 50px;
}

.containerBotonCerrar{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@media (max-width: 600px) {
    .modal {
        max-width: 300px;
        width: 90%;
    }
}