.container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
}

.title {
 /*  margin-bottom: 40px; */
  color: gray;
  margin: 0px auto 40px;
}

.optionsContainer {
  display: flex;
  justify-content: space-around;
}

.optionCard {
  padding: 20px;
  border: 2px solid lightgray;
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.3s, border-color 0.3s;
}

.optionCard:hover {
  border-color: gray;
}

.evento{
  background-color: #32ADE6;
}

.experiencia{
  background-color: #AF52DE;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 2em;
  margin-bottom: 10px;
}

.optionTitle {
  margin-top: 0;
  margin-bottom: 10px;
}

.optionDescription {
  margin: 0;
}

.footer{
  margin-top: 50px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

/* .buttonContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
} */


.continueButton {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.continueButton:hover {
  background-color: darkgray;
}

.flechaAvanzarIcono {
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
}

.errorText{
  text-align: end;
  color: #FF6F61;
}

@media (max-width: 800px) {
  .optionsContainer {
      display: flex;
      flex-direction: column;
  }
  .optionCard{
    margin-right: 0px;
    margin-bottom: 10px;
  }
}