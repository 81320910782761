nav {
    width: 100%;
    height: 5em;
    background-color: rgb(37, 39, 53);
    display: flex;
    justify-content: flex-start;
    padding: 1em;
    z-index: 999;
}

.navbar {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #0273FD;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    box-sizing: border-box;
}

/* ---------------- Navbar and burger menu ------------------ */


.burgerMenu {
    height: 100%;
    width: 4em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
}

.burgerBar {
    width: 4em;
    height: 0.5em;
    background-color: rgb(0, 183, 255);
    border-radius: 0.5em;
}

.menu {
    width: 50%;
    height: 100vh;
    background-color: rgb(154, 181, 240);
    position: absolute;
    top: 0;
    z-index: -1;
}

/* ------------- sliding menu ------------------ */
.hidden {
    display: none;
}

.visible {
    display: inherit;
}

/* ----- animations--------- */
/* clicked */
.burgerBar.clicked:nth-child(1) {
    transform: rotate(45deg) translate(0.75em, 1.25em);
    transition: ease-out 0.5s;
}

.burgerBar.clicked:nth-child(2) {
    transform: scale(0.1);
    transition: ease-out 0.5s;
}

.burgerBar.clicked:nth-child(3) {
    transform: rotate(135deg) translate(-0.5em, 1em);
    transition: ease-out 0.5s;
}

/* unclicked */
.burgerBar.unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}

/* .navbar+* {
    margin-top: 60px;
} */

.navbarBrand {
    color: #fff;
    font-size: 20px;
    margin-left: 20%;
    flex-grow: 1;
}

.navbarDiv {
    margin-right: 20%;
}

.navbarMenu {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}

.navbarToggle {
    display: none;
}

.navbarToggleIcon {
    font-size: 40px;
    background-color: 'red';
}

.navbarItem {
    position: relative;
    margin-left: 50px;
    font-size: 18px;
    letter-spacing: 1px;
    color: #fff;
    cursor: pointer;
}

.navbarItem a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
    font-weight: 600;
}

.navbarItem a:hover {
    color: #ccc;
    font-weight: bold;
}

.navbarItem.open {
    position: relative;
}

.navbarDropdownMenu {
    position: absolute;
    margin-top: 20px;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.navbarDropdownMenu li {
    margin-bottom: 12px;
    white-space: nowrap;
    display: flex;
  align-items: center;
}

.navbarDropdownMenu li svg {
    vertical-align: middle;
  }

.navbarDropdownMenu li:last-child {
    margin-bottom: 0;
}

.navbarDropdownMenu a {
    color: #0273FD;
    text-decoration: none;
    font-size: 18px;
    margin-left: 10px;
}

.navbarDropdownMenu a:hover {
     color: #ccc;
    font-weight: bold;
}

@media (max-width: 1150px) {
    .navbarBrand {
        margin-left: 5%;
    }

    /* .navbar-menu {
        margin-left: 5%;
    } */
}

@media (max-width: 900px) {
    .navbarBrand {
        text-align: center;
        margin-left: 20%;
    }

    .navbarMenu {
        display: none;
    }

    .navbarToggle {
        display: block;
        position: absolute;
        text-align: left;
        background-color: transparent;
        border: none;
        z-index: 9999;
    }

    .navbarDiv.open {
        display: flex;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.8);
        position: fixed;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        z-index: 99;
    }

    .navbarMenu.open {
        display: flex;
        flex-direction: column;
        align-items: first baseline;
        position: fixed;
        margin-top: 100px;
        width: 50%;
        height: 100%;
        z-index: 99;
    }

    .navbarMenu.open li.navbarItem {
        text-align: left;
        padding: 10px;
        color: #0273FD;
    }
}