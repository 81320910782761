.containerRestablecerPassword {
    display: flex;
    margin-bottom: 50px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.formGroupRestablecerPassword {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.formLabelRestablecerPassword {
    color: #0273fd;
    font-weight: bold;
    font-size: 20px;
}

.formInputRestablecerPassword {
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    white-space: pre-wrap;
    font-size: 20px;
}

.formInputRestablecerPassword.focused {
    border-color: #0273FD;
    background-color: #f2f7ff;
}


.submitButtonRestablecerPassword {
    background-color: #0273fd;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
    font-size: 20px;
}

.submitButtonRestablecerPassword:hover {
    background-color: #75a2e1;
    color: #ccc;
}

.textoError{
    color: #FF4D4D;
    font-size: 15px;
}

.containerPassword{
    position: relative;
}

.togglePasswordButton {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }

@media (max-width: 1000px) {

    .formInput {
        width: calc(100% - 16px);
    }
}

