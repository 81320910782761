.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.inputWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}

.togglePasswordButton {
    position: absolute;
    top: 65%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

.input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 16px;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 10px;
}

.input:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* .activeInput {
    border-color: #0273FD;
    background-color: #f2f7ff;
} */

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin-bottom: 8px;
}

.error {
    color: #FF0000;
    margin-bottom: 5px;
    text-align: center;
}

.buttonLogin {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: black;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.buttonLogin:hover {
    background-color: darkgray;
}

.focused {
    border-color: #0273FD;
    background-color: #f2f7ff;
}


.textoOlvidoPassword {
    text-decoration: underline;
    cursor: pointer;
    margin: 20px;
}

.textoOlvidoPassword:hover {
    color: #3366FF;
    text-decoration: underline;
}

.containerRegistro{
    margin: 20px;
}

.textoSmall {
    color: gray;
    font-size: 12px;
}

.textoRegistro {
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;
}

.textoRegistro:hover {
    color: #3366FF;
    text-decoration: underline;
}

.containerGoogle{
    margin-top: 20px;
}

@media (max-width: 900px){
    .inputContainer{
        width: 70%;
    }
}


@media (max-width: 450px) {
    .inputContainer{
        width: 90%;
    }

}