

.textoIrPoliticaPrivacidad {
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;
}

.textoIrPoliticaPrivacidad:hover {
    color: #3366FF;
    text-decoration: underline;
}