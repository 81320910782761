.containerPerfil {
    display: flex;
    margin-bottom: 50px;
    justify-content: center;
}

.botonesPerfil {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
}

.botonLogoutPerfil {
    background-color: transparent;
    color: #0273FD;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    margin-right: 50px;
    border-radius: 5px;
    border: 1px solid black;
    transition: background-color 0.3s;
}

.botonLogoutPerfil:hover {
    color: #75a2e1;
    background-color: #ccc;
}

.botonEliminarPerfil {
    background-color: #FF4D4D;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 50px;
    transition: background-color 0.3s;
    font-weight: bold;
    font-size: 18px;
}

.botonEliminarPerfil:hover {
    background-color: #FF7676;
    color: #ccc;
}

.error {
    color: #FF4D4D;
    font-size: 15px;
}

.formGroupPerfil {
    margin-bottom: 20px;
}


.formInputPerfil {
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    white-space: pre-wrap;
    font-size: 20px;
}

.textoOlvidoPassword {
    color: #0000FF;
    text-decoration: underline;
    cursor: pointer;
}

.textoOlvidoPassword:hover {
    color: #3366FF;
    text-decoration: underline;
}

.containerCambiosPassword {
    display: flex;
    flex-direction: row;
}

.containerInput {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.iconoEditarPassword {
    margin-left: 20px;
    cursor: pointer;
}


@media (max-width: 500px) {
    .botonesPerfil {
        display: flex;
        flex-direction: column;
    }

    .botonLogoutPerfil {
        margin-top: 50px;
        margin-bottom: 50px;
        margin-right: 0px;
    }

    .botonEliminarPerfil {
        margin-left: 0px;
    }
}