/* Estilos generales para el contenido */
/* footer {
    
 
} */

.footer { 
    padding: 1rem;
    background-color: #333333;
    /* background-color: #222222; */
}

.sectionInfo {
    margin-left: 20%;
    margin-right: 20%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    list-style-type: none;
    width: 80%;
}

.sectionLegal {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20%;
    margin-right: 20%;
    gap: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.sectionLegal p,
.sectionLegal a {
    margin: 0;
    text-decoration: none;
    color: #CCCCCC;
}

.sectionLegal a:hover {
    color: #0273FD;
}

.navbarFooter li {
    margin-bottom: 10px;
}

.navbarFooter li a {
    margin-bottom: 10px;
    text-decoration: none;
    color: #CCCCCC;
    font-size: 20px;
}

.navbarFooter li a:hover {
    color: #0273FD;
}

.divApp{
    display: flex;
    flex-direction: column;
}

.divApp p{
    color: #CCCCCC;
    font-size: 20px;
}

.divRedesSociales p{
    color: #CCCCCC;
    font-size: 20px;
}

.contactoProvisional{
    color: #CCCCCC;
    font-size: 18px;
    text-align: center;
    margin: 30px;
}

.derechos{
    color: #CCCCCC;
    font-size: 18px;
    text-align: center;
    margin: 30px;
}

.googlePlayBadgeFooter{
    width: 200px;
    height: 80px;
}

.appleBadgeFooter{
    width: 200px;
    height: 80px;
}

@media (max-width: 1100px) {
    .sectionLegal {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .footer {
        margin-left: 0;
        margin-right: 0;
    }
    .sectionInfo {
        margin-left: 0;
        margin-right: 0;
    }
    .sectionLegal {
        margin-left: 0;
        margin-right: 0;
    }
}


@media (max-width: 500px) {
    .navbarFooter{
        display: none;
    }
}