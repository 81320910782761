.cookieBar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f1f1f1;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .cookieBar p {
    font-size: 14px;
    color: #333;
  }
  
  .cookieBar button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .cookieBar button:hover {
    background-color: #0056b3;
  }
  