.container {
    margin-bottom: 50px;
}

.formGroup {
    margin-bottom: 30px;
    width: 90%;
    display: flex;
    flex-direction: column;
}

.textAreaPlanItem {
    min-height: 150px;
    resize: vertical;
}


.fechaPlanItem {
    width: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    white-space: pre-wrap;
    font-size: 20px;
}

.archivoPlanItem {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    white-space: pre-wrap;
    font-size: 20px;
}

.buttonNuevoCartel {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 1em;
    width: 60%;
    color: white;
    background-color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.buttonNuevoCartel:hover {
    background-color: darkgray;
}

.containerCartelPlan {
    flex: 0 0 auto;
}

.containerCartelPlan img {
    width: 400px;
    height: 400px;
}

.contactoSwitch {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.switch {
    margin-left: 10px;
    /* Ajusta el margen según sea necesario */
}

.botonesSubmit {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

.buttonSubmitDelete {
    background-color: #FF4D4D;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
    font-size: 15px;
}

.buttonSubmitDelete:hover {
    background-color: #FF7676;
    color: #ccc;
}

.buttonSubmitUpdate {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.buttonSubmitUpdate:hover {
    background-color: darkgray;
}

.loading {
    display: flex;
    justify-content: center;
}

.mensaje {
    text-align: center;
}

.error {
    color: #FF4D4D;
    font-size: 15px;
    text-align: center;
}


@media (max-width: 500px) {
    .containerCartelPlan img {
        width: 250px;
        height: 250px;
    }
}