.patrocinadorCard {
  flex: 1;
  position: relative;
  display: flex;
  gap: 10px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  /* width: 50%; */
  margin-bottom: 10px;
  margin-top: 10px;
  box-shadow: 0 2px 3.84px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* .patrocinadorCard::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.patrocinadorCard:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.patrocinadorCard:hover::after {
  opacity: 1;
} */

.patrocinadorImage {
  flex: 0 0 auto;
  /* Establece el tamaño de la imagen como auto */
}

.patrocinadorImage img {
  width: 150px;
  height: 250px;
  border-radius: 10px;
}

.patrocinadorInfo {
  flex: 1;
  margin-left: 10px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  /* Ensure space between elements */
  align-items: flex-start;
}

.patrocinador {
  background-color: #65C466;
  /* Color de fondo para "patrocinadoro" */
  color: #FFFFFF;
  /* Color de texto blanco */
  font-size: 12px;
  font-weight: bold;
  padding: 8px;
  /*  padding-horizontal: 10px;
    padding-vertical: 5px; */
  border-radius: 30px;
  overflow: hidden;
  align-self: flex-start;
  display: inline-block;
  /* fontFamily: "Arial" */
}

.patrocinadorTitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
  /* Oculta el contenido que excede el ancho máximo */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* Establece la dirección vertical */
  text-overflow: ellipsis;
  /* Agrega puntos suspensivos cuando el texto se desborda */
}

.patrocinadorDescription {
  font-size: 16px;
  max-width: 200px;
  overflow: hidden;
  /* Oculta el contenido que excede el ancho máximo */
  color: #333333;
}

.buttonContainer {
  align-self: flex-end;
  margin-top: auto;
}

.continueButton {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  font-size: 1em;
  color: white;
  background-color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.continueButton:hover {
  background-color: darkgray;
}

.flechaAvanzarIcono {
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
}


@media (max-width: 1500px) {
/* 
  .patrocinadorImage img {
    width: 130px;
    height: 200px;
  } */

  .patrocinadorDescription {
    -webkit-line-clamp: 3;
  }

}

@media (max-width: 1200px) {
  .patrocinadorImage img {
    width: 120px;
    height: 180px;
  }

  .patrocinadorDescription {
    -webkit-line-clamp: 2;
    max-width: 200px;
  }
}

@media (max-width: 500px) {

  /* .patrocinadorImage img {
      width: 120px;
      height: 180px;
    } */
  .patrocinadorDescription {
    width: 100% auto;
  }

  .patrocinadorTitle {
    width: 100% auto;
  }

  /* .patrocinadorCard {
      width: 50% auto;
    } */
}




/* 
    .patrocinadorCard {
      position: relative; 
      display: flex;
      align-items: center;
      background-color: #fff;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 10px;
      box-shadow: 0 2px 3.84px rgba(0, 0, 0, 0.25);
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
    
    .patrocinadorCard::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 10px;
      opacity: 0;
      transition: opacity 0.3s ease; 
    }
    
    .patrocinadorCard:hover {
      background-color: rgba(255, 255, 255, 0.8); 
    }
    
    .patrocinadorCard:hover::after {
      opacity: 1; 
    }
    .patrocinadorImage img {
      width: 150px;
      height: 150px;
      border-radius: 10px;
    }
    
    .patrocinadorDetails {
      flex: 1;
      margin-left: 10px;
      max-width: 80%; 
    }
    
    .patrocinadorTitle {
      font-size: 20px;
      font-weight: bold;
      margin-left: 5px;
      margin-top: 5px;
      color: #167efd;
    }
    
    .patrocinadorDate {
      font-size: 16px;
      margin-left: 5px;
      color: #6a87f1;
    }
    
    .patrocinadorDescription {
      font-size: 16px;
      margin-left: 5px;
    }
     */