.header {
    background-color: #32ADE6;
    padding: 16px;
}

.upContainer {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.downContainer {
    display: flex;
    align-items: center;
}

.experiencia {
    background-color: #AF52DE;
}

.evento {
    background-color: #32ADE6;
}

.icon {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 10px;
    font-size: 24px;
    cursor: pointer;
}

.titulo {
    font-size: 1.875rem;
    font-weight: bold;
    margin-left: 8px;
    flex: 1;
}

.location {
    font-size: 1rem;
    margin-left: 8px;
    margin-top: 10px;
    flex: 1;
}

.error {
    color: red;
}
