.sectionHome {
    /*    flex: 1; */
    margin-bottom: 50px;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
}

/* 
.formGroup {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
} */
.loading {
    display: flex;
    justify-content: center;
}

.planesCard {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-top: 20px;
}

.planesCard>* {
    max-width: 800px;
    max-height: 450px;
    /* Establece el ancho máximo deseado */
}

.containerNoPlans {
    margin-top: 40px;
    text-align: center;
}

.paginationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}


/* .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
}

.pagination li {
    color: #0273fd;
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination li:hover {
    background-color: #f0f0f0;
}

.pagination li.active {
    background-color: #0273fd;
    color: #fff;
}

.paginationDisabled {
    border: none !important;
    background-color: red;
    cursor: default;
} */





@media (max-width: 1000px) {
    .planesCard {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 400px) {
    .rangoKilometros {
        width: 100%;
    }
}