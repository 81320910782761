.container {
    margin-bottom: 50px;
}

.title {
    margin-top: 50px;
    margin-bottom: 40px;
    color: gray;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

/* .buttonContainer {
    display: flex;
    justify-content: center;
    margin: 20px 0;
} */

.volverButton {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 1em;
    margin-right: 20px;
    border: 2px solid black;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.volverButton:hover {
    background-color: darkgray;
}

.flechaVolverIcono {
    display: inline-flex;
    align-items: center;
}

.continueButton {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.continueButton:hover {
    background-color: darkgray;
}

.flechaAvanzarIcono {
    margin-left: 8px;
    display: inline-flex;
    align-items: center;
}