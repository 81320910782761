.containerFechas {
    display: flex;
    align-items: center;
  /*   justify-content: space-between; */
}

.textoPregunta{
  margin-bottom: 20px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-right: 40px;
}


@media (max-width: 750px) {
  .formGroup {
      width: 100%;
  }
}
  