.loadingIndicator {
    margin: 5px auto;
    height: 40px;
    display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #0273fd;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }