/* .containerDeleteUpdate{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    margin-bottom: 50px;
} */


.listaPlanItem {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 100px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.planItem {
    background-color: #F8F8FF;
    border-radius: 10;
    padding: 20px;
    margin-bottom: 50px;
}

.noPlanes{
    text-align: center;
}

@media (max-width: 1300px) {

    .listaPlanItem {
        grid-gap: 30px;
    }
}

@media (max-width: 1100px) {

    .listaPlanItem {
         display: flex;
        flex-direction: column;
    }
}