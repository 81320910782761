.container {
    margin-top: 50px;
    margin-bottom: 50px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}

.formTextArea{
    min-height: 150px;
    resize: vertical;
}

.textoSmall {
    color: gray;
    font-size: 12px;
}

.formArchivo {
    border: 2px solid #000;
    border-radius: 10px;
    padding: 10px;
    width: 40%;
    cursor: pointer;
}

.formArchivo:hover {
    background-color: rgb(239, 233, 233);
}

/* 
.closeIcon {
    position: absolute;
    top: 30px; 
    left: 10px; 
    cursor: pointer;
    color: red;
    font-size: 20px;
} */
.imageContainer{
/*     position: relative;
    display: inline-block; */
    margin-bottom: 20px;
}

.selectedFile {
    width: 300px;
    height: 300px;
    max-width: '50%';
    max-height: '50%';
    margin-top: '10px';
}


/* .buttonContainer {
    display: flex;
    justify-content: center;
    margin: 20px 0;
} */

.containerButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.volverButton {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 1em;
    margin-right: 20px;
    border: 2px solid black;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.volverButton:hover {
    background-color: darkgray;
}

.flechaVolverIcono {
    display: inline-flex;
    align-items: center;
}

.continueButton {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}


.continueButton:hover {
    background-color: darkgray;
}

.flechaAvanzarIcono {
    margin-left: 8px;
    display: inline-flex;
    align-items: center;
}

.error{
    color: #FF6F61;
}

@media (max-width: 750px) {
    .formArchivo {
        width: 95%;
    }
}