.locationSearchContainer {
    position: relative;
    width: 100%;
}

/* .inputButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
} */

.botonBuscar {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    font-size: 1em;
    color: white;
    background-color: black;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.botonBuscar:hover {
    background-color: darkgray;
}

.locationSearchInput{
    border-radius: 30px;
}

.locationSearchResults {
/*     width: 800px; */
    position: absolute;
    left: 0;
    max-height: 200px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin: 4px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
}

.locationSearchResults li {
    padding: 8px 12px;
    cursor: pointer;
}

.locationSearchResults li.selected {
    background-color: #f2f2f2;
}

.locationSearchResults li:hover {
    background-color: #f2f2f2;
}

.searchButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 50%;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    background-color: transparent;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    margin-left: 10px;
    transition: all 0.3s ease;
}

.searchButton:hover {
    border-color: black;
    background-color: #0273fd;
    color: white;
    transform: scale(1.1);
}


@media (max-width: 1000px) {

    .locationSearchInput,
    .locationSearchResults {
       
        /* Reducir el ancho en dispositivos móviles */
        /* width: calc(100% - 16px);
        margin-left: 8px;
        margin-right: 8px; */
        
    }

    .botonBuscar{
        margin-right: 5%;
    }
}