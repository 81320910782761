.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%;
    max-height: 90%;
    background-color: #fff;
    padding: 20px;
}

.modal-body {
    overflow-y: auto;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.infoModalPlan {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.imagenModalPlan {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: 600px;
    border-radius: 20px;
}

.tituloModalPlan{
    font-size: 20px;
    color: #0273FD;
    text-align: center;
}

.direccionModalPlan{
    font-size: 18px;
    color: #0273FD;
}

.fechaModalPlan{
    font-size: 18px;
    color: #6a87f1;
}

.botonesModalPlan{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.botonCerrarModalPlan {
    background-color: transparent;
  color: #0273FD;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
  transition: background-color 0.3s;
}

.botonCerrarModalPlan:hover {
    color: #333;
    background-color: #ccc;
}

.botonComoLlegarPlan{
    background-color: #0273fd;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
    font-size: 18px;
}

.botonComoLlegarPlan:hover {
    background-color: #75a2e1;
    color: #ccc;
}

/* .botonesModalPlan{
    
} */


/* Estilos responsivos */
@media (max-width: 600px) {
    .modal {
        max-width: 300px;
        width: 90%;
    }
    .imagenModalPlan{
        max-height: 400px;
    }
}