.planCard {
  flex: 1;
  position: relative;
  display: flex;
  /*   gap: 10px; */
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  min-height: 150px;
  /* width: 50%; */
  margin-bottom: 10px;
  margin-top: 10px;
  box-shadow: 0 2px 3.84px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.planCard::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.planCard:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.planCard:hover::after {
  opacity: 1;
}

.planImage {
  flex: 0 0 auto;
  /* Establece el tamaño de la imagen como auto */
}

.planImage img {
  width: 150px;
  height: 250px;
  border-radius: 10px;
}

.planInfo {
  flex: 1;
  display: flex;
  margin-left: 10px;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  /*  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px; */
}

.tipoPlan {
  font-size: 12px;
  font-weight: bold;
  padding: 8px;
  /*  padding-horizontal: 10px;
  padding-vertical: 5px; */
  border-radius: 30px;
  overflow: hidden;
  align-self: flex-start;
  display: inline-block;
  /* fontFamily: "Arial" */
}

.experiencia {
  background-color: #AF52DE;
  /* Color de fondo para "experiencia" */
  color: #FFFFFF;
  /* Color de texto blanco */
}

.evento {
  background-color: #32ADE6;
  /* Color de fondo para "plano" */
  color: #FFFFFF;
  /* Color de texto blanco */
}

.destacado{
  background-color: black;
  color: #FFFFFF;
}


.planTitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;

  /* Oculta el contenido que excede el ancho máximo */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* Establece la dirección vertical */
  text-overflow: ellipsis;
  /* Agrega puntos suspensivos cuando el texto se desborda */
}

.planDescription {
  font-size: 16px;
  overflow: hidden;
  /* Oculta el contenido que excede el ancho máximo */
  color: #333333;
  word-break: break-word; /* This will break long words to fit the container */
  overflow-wrap: break-word; 
/*   flex: 1; */
/*   max-width: 200px; */
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  /* Establece la dirección vertical */
  text-overflow: ellipsis;
  /* Agrega puntos suspensivos cuando el texto se desborda */
}

.planFooter {
  margin-top: auto;
  /* Empuja este contenedor hacia abajo */
  width: 100%;
}

.planPrecio {
  margin-top: auto;
  font-size: 14px;
  /* flex: 1;
  justify-content: end; */
}

.horizontalBar {
  width: 100%;
  height: 1px;
  background-color: #E0E0E0;
  margin: 10px 0;
  margin-top: auto;
}

.containerMunicipioYDistancia {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
}

.planDate {
  font-size: 14px;
}

.planMunicipio {
  font-size: 14px;
  overflow: hidden;
  /* Oculta el contenido que excede el ancho máximo */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* Establece la dirección vertical */
  text-overflow: ellipsis;
  /* Agrega puntos suspensivos cuando el texto se desborda */
}

.planDistancia {
  color: #8E8E93;
  font-size: 12px;
}





@media (max-width: 1500px) {

/*   .planImage img {
    width: 130px;
    height: 200px;
  } */

/*   .planDescription {
    -webkit-line-clamp: 3;
  }
 */
}

@media (max-width: 1200px) {
  .planImage img {
    width: 120px;
    height: 180px;
  }

  .planDescription {
    -webkit-line-clamp: 2;
  }
}

@media (max-width: 1000px) {
  .planDescription {
    -webkit-line-clamp: 4;
  }
}

@media (max-width: 800px) {
  .planDescription {
    -webkit-line-clamp: 2;
  }
}

@media (max-width: 500px) {

  /* .planImage img {
    width: 120px;
    height: 180px;
  } */
  .planDescription {
    width: 100% auto;
  }

  .planTitle {
    width: 100% auto;
  }

  /* .planCard {
    width: 50% auto;
  } */
}




/* 
  .planCard {
    position: relative; 
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 2px 3.84px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .planCard::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.3s ease; 
  }
  
  .planCard:hover {
    background-color: rgba(255, 255, 255, 0.8); 
  }
  
  .planCard:hover::after {
    opacity: 1; 
  }
  .planImage img {
    width: 150px;
    height: 150px;
    border-radius: 10px;
  }
  
  .planDetails {
    flex: 1;
    margin-left: 10px;
    max-width: 80%; 
  }
  
  .planTitle {
    font-size: 20px;
    font-weight: bold;
    margin-left: 5px;
    margin-top: 5px;
    color: #167efd;
  }
  
  .planDate {
    font-size: 16px;
    margin-left: 5px;
    color: #6a87f1;
  }
  
  .planDescription {
    font-size: 16px;
    margin-left: 5px;
  }
   */