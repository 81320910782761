.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.formGroup {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
}

.formInputPerfil{
    width: 50%;
}

.botonesModal {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
}

.botonActualizar {
    background-color: #0273fd;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
    font-size: 16px;
}

.botonActualizar:hover {
    background-color: #75a2e1;
    color: #ccc;
}

.botonCancelar {
    color: #0273fd;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: bold;
    font-size: 16px;
}

.botonCancelar:hover {
    color: #75a2e1;
    background-color: #ccc;
}

.tituloModal {
    text-align: center;
    margin-bottom: 50px;
}

.textoError {
    color: #FF4D4D;
    font-size: 15px;
}

.inputWrapper {
    position: relative;
    width: 90%;
}

.togglePasswordButton {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

/* Estilos responsivos */
@media (max-width: 600px) {
    .modal {
        max-width: 300px;
        width: 90%;
    }
}