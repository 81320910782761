.container {
    margin-right: 20%;
    margin-left: 20%;
    margin-bottom: 20px;
}

.titulo{
    margin-top: 50px;
}

.info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.containerTextInfo,
.imageContainer {
    flex: 1;
    min-width: 45%;
    margin: 20px;
    box-sizing: border-box;
}

.textInfo {
    text-align: justify;
    text-justify: inter-word; /* Aplica una justificación más equilibrada */
    hyphens: auto; /* Permite la división de palabras */
    -ms-hyphens: auto; /* IE 10+ */
    -webkit-hyphens: auto; 
}

.imageContainer {
    /*   display: flex;
    justify-content: center; */
    display: flex;
    justify-content: center;
    align-items: center;

}

.image {
    max-width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    /* Asegura que la imagen ocupe todo el espacio disponible */
    width: 100%;
    height: 100%;
    /* Asegura que la imagen ocupe el mismo espacio que el contenedor */
    aspect-ratio: 16/9;
}

.infoFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.textoPregunta {
    text-align: center;
}

.customList {
    list-style: none;
    padding-left: 0;
}

.customList li {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    margin-bottom: 15px;
    text-align: justify;
}

.icon {
    margin-right: 0.5rem;
    color: #32ADE6;
    font-size: 26px;
    flex-shrink: 0;
}

@media (max-width: 800px) {
    .info {
       flex-direction: column;
    }
    .container{
        margin-right: 5%;
        margin-left: 5%;
    }
     /* Cambia el orden de los elementos para mobile */
     .info:first-of-type .imageContainer {
        order: 1;
    }
    .info:first-of-type .containerTextInfo {
        order: 2;
    }
    .info:nth-of-type(3) .containerTextInfo {
        order: 2;
    }
    .info:nth-of-type(3) .imageContainer {
        order: 1;
    }
}