.containerDistancia {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* flex-grow: 1; */
}

.containerTextoDistancia {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.distancia {
    color: #000000;
}

.kilometros {
    color: #000000;
}

.containerInput{
    width: 30%;
    margin-left: 5px;
}

.rangeInput {
    width: 100%;
    height: 8.4px;
    border-radius: 5px;
    background-image: linear-gradient(to right, #000000 0%, #e1e1e1 0%);
    background-size: 50% 100%;
    background-repeat: no-repeat;

    outline: none;
    transition: background 0.3s ease;

   /*  .rangeInput {
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background: linear-gradient(to right, #000000 0%, #e1e1e1 0%);
    
    -webkit-appearance: none; */
}


.rangeInput::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: .3s ease-in-out;
}

.rangeInput::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}

/* input[type=range]::-moz-range-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: .3s ease-in-out;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
}

input[type=range]::-moz-range-progress {
    background-color: #0273FD;
} */

.rangeInput::-ms-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: .3s ease-in-out;
}

.rangeInput::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}

.rangeInput::-ms-fill-lower {
    background: #000000;
}

.rangeInput::-ms-fill-upper {
    background: lightblue;
}

@media (max-width: 900px) {
    .containerInput{
        width: 50%;
    }
}


@media (max-width: 400px) {
    .rangoKilometros {
        width: 100%;
    }
    .containerDistancia{
        flex-direction: column;
        margin-bottom: 15px;
    }
    .containerInput{
        width: 90%;
    }
}